define("discourse/plugins/discourse-plugin-auth0-sso/discourse/initializers/sso-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "sso-refresh",
    after: "message-bus",
    initialize: function (container) {
      const messageBus = container.lookup('message-bus:main');
      const keyValueStore = container.lookup('key-value-store:main');
      if (!messageBus) {
        return;
      }
      messageBus.subscribe("/sso-refresh", function () {
        console.log('sso-refresh message received');
        keyValueStore.abandonLocal();
        window.location.pathname = Discourse.getURL('/');
      });
    }
  };
});